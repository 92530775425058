@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

body {
  margin: 0;
}

a:hover,
a:focus {
  text-decoration: none !important;
  color: inherit !important;
}

// Fonts

.montserrat {
  font-family: "Montserrat";
}

.open-sans {
  font-family: "Open Sans";
}

// Text

.break-always {
  word-spacing: 10000px;
}

// Color

.bg-gradient-primary {
  background-image: linear-gradient(135deg, #f12b5c 0%, #e4113e 50%, #ff6a00 100%) !important;
}

.bg-primary {
  background-color: #e4113e;
}

// Input

.text-sm .p-inputtext {
  font-size: 0.875rem;
}

input::-webkit-file-upload-button {
  display: none;
}

.default-chakra-input {
  appearance: none;
  width: 100%;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  border-radius: 0.375rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border: 1px solid #ced4da;
  transition: all 0.2s;
  padding-top: 12px;
  padding-bottom: 12px;

  &:hover {
    border-color: #cbd5e0;
  }

  &:focus {
    border-color: #3182ce;
    box-shadow: 0 0 0 1px #3182ce;
  }
}

// Prime React Input
.p-calendar {
  .p-inputtext {
    background-color: transparent !important;
  }
}

.p-datepicker-trigger {
  @extend .bg-gradient-primary;
  border: 0 !important;
}

.p-selectbutton {
  .p-button:not(.p-highlight) {
    background: rgb(249, 250, 251) !important;
  }

  .p-button-label {
    font-weight: 500;
    background-color: transparent;
    font-size: 0.8rem;
  }

  .p-highlight {
    @extend .bg-gradient-primary;
    border: 0 !important;
  }
}

// Rsuite
// Datepicker
.datepicker div.rs-picker-toggle.rs-btn.rs-btn-default {
  padding: 10px !important;
  border-color: #cbd5e0;
}

// Dropdown
.p-dropdown {
  .p-dropdown-label {
    white-space: normal;
  }
}

.p-dropdown-trigger-icon {
  font-size: 0.8rem !important;
}

.p-dropdown-items-wrapper {
  max-width: 500px;

  li.p-dropdown-item {
    white-space: pre-wrap;
    font-size: 0.875rem;
    padding: 8px 20px !important;
  }
}

.p-0 .p-inputtext {
  padding: 0 !important;
}

.text-white .p-inputtext,
.text-white .p-dropdown-trigger-icon {
  color: white;
}

// Chart
.chart {
  text {
    font-size: 12px !important;
  }
}
